import React from 'react';
import { createStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingBottom: theme.spacing(3),
    },

    title: {
      fontWeight: 'bold',
      alignSelf: 'center',
    },

    gap: {
      flexGrow: 1,
    },

    content: {
      '& > :not(:first-child)': {
        marginLeft: theme.spacing(4),
      },
    },
  });

const ActionToolbar = ({
  classes,
  title,
  left,
  back,
  children,
}: {
  classes: any;
  title?: string;
  back?: React.ReactNode;
  left?: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
}) => (
  <Box className={classes.root}>
    {back}
    <Box className={classes.title}>{title}</Box>
    {left}
    <Box className={classes.gap} />
    <Box className={classes.content}>{children}</Box>
  </Box>
);

export default withStyles(styles)(ActionToolbar);
