import React, { useEffect, useState } from 'react';
import { keyBy } from 'lodash';
import { TextField, DateField, useDataProvider, useVersion } from 'react-admin';
import Card from '@material-ui/core/Card';

import { Datagrid } from 'components/list';
import Pagination from 'components/pagination/Pagination';

const ActivityTab = (props) => {
  const dataProvider = useDataProvider();
  const version = useVersion();

  const [activity, setActivity] = useState<any>([]);
  const [currentTotal, setCurrentTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(5);

  useEffect(() => {
    const getActivityData = async () => {
      const { data, total } = await dataProvider.getList('userActivity', {
        pagination: { page, perPage },
        filter: { tenant: props.id },
      });

      setActivity(data);
      setCurrentTotal(total);
    };

    getActivityData();
  }, [dataProvider, page, perPage, version, props.id]);

  const updatePerPage = (newPerPage) => {
    if (page > Math.ceil(currentTotal / newPerPage)) {
      setPage(1);
    }
    setPerPage(newPerPage);
  };

  return (
    <Card>
      <Datagrid
        data={keyBy(activity, 'id')}
        ids={activity.map(({ id }) => id)}
        resource="userActivity"
        currentSort={{ field: 'createdAt', order: 'ASC' }}>
        <TextField source="description" label="Action" sortable={false} />
        <TextField source="adminUsername" label="Admin" sortable={false} />
        <DateField
          source="createdAt"
          label="Date"
          options={{
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }}
          sortable={false}
        />
      </Datagrid>
      <Pagination
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={updatePerPage}
        total={currentTotal}
      />
    </Card>
  );
};

export default ActivityTab;
