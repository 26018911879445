import React, { useMemo } from 'react';
import {
  Avatar,
  Chip,
  FormControl,
  FormGroup,
  FormLabel,
  Button,
  Grow,
  makeStyles,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { get, uniq } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: (showLabel) => (showLabel ? theme.spacing(2) : 0),
    paddingBottom: (showLabel) => (showLabel ? theme.spacing(2) : 0),
  },
  group: {
    flexDirection: 'row',
    paddingTop: (showLabel) => (showLabel ? theme.spacing(2) : 0),
  },
  chip: {
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  avatar: {
    height: 20,
    width: 20,
    marginRight: theme.spacing(),
  },
  labelIcon: {
    marginRight: theme.spacing(2),
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ChipsArrayInput = (props) => {
  const {
    label,
    choices,
    optionAvatar,
    optionValue,
    optionLabel,
    input: { value, onChange },
    icon,
    showLabel = true,
    showSelectAll = true,
  } = props;

  const classes = useStyles(showLabel);

  const _choices = uniq(choices);

  const handleClick = (id) => {
    if (value && value.includes(id)) {
      onChange(value.filter((v) => v !== id));
    } else {
      onChange([...value, id]);
    }
  };

  const selectAll = () => onChange(_choices.map((i) => get(i, optionValue)));

  const deselectAll = () => onChange([]);

  const allSelected = value.length === _choices.length;

  return (
    <FormControl className={classes.root}>
      {showLabel && (
        <FormLabel className={classes.label}>
          {icon && React.createElement(icon.type, { className: classes.labelIcon })}
          {label}
          {showSelectAll && (
            <Button onClick={allSelected ? deselectAll : selectAll} size="small" color="primary">
              {allSelected ? 'Deselect all' : 'Select all'}
            </Button>
          )}
        </FormLabel>
      )}
      <FormGroup className={classes.group}>
        {useMemo(
          () =>
            _choices.map((choice, i) => {
              const _id = get(choice, optionValue);
              const _selected = Boolean(value && value.includes(_id));
              const _label = get(choice, optionLabel);

              return (
                <Grow key={_id} in timeout={60 * i}>
                  <Chip
                    className={classes.chip}
                    variant="outlined"
                    icon={_selected ? <CheckIcon color="primary" /> : undefined}
                    label={
                      <>
                        {optionAvatar && (
                          <Avatar
                            className={classes.avatar}
                            alt={_label}
                            src={get(choice, optionAvatar)}
                          />
                        )}
                        {_label}
                      </>
                    }
                    onClick={() => handleClick(_id)}
                  />
                </Grow>
              );
            }),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [_choices]
        )}
      </FormGroup>
    </FormControl>
  );
};

export default ChipsArrayInput;
