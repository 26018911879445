import React, { forwardRef, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { useDataProvider, useRefresh, useNotify } from 'react-admin';
import { Form } from 'react-final-form';

import { Dialog } from 'components/common';
import { Input } from 'components/form';
import { required } from 'components/form/validationRules';

const EnableUserAction = forwardRef((props: any, ref: any) => {
  const {
    record: { cognitoEnabled, id: rolloId, cognitoId },
  } = props;

  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleEnableUser = async () => {
    try {
      await dataProvider.post('enableUser', {
        data: {
          rolloId,
          cognitoId,
        },
      });

      await dataProvider.post('unblockFraud', {
        data: {
          rolloId,
          cognitoId,
        },
      });

      refresh();
    } catch (error: any) {
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      );
    }
  };

  const handleDisableUser = async ({ note }) => {
    try {
      const response = await dataProvider.post('disableUser', {
        data: {
          rolloId,
          cognitoId,
          note,
        },
      });

      handleCloseDialog();

      refresh();

      return response;
    } catch (error: any) {
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      );
    }
  };

  return (
    <>
      <MenuItem ref={ref} onClick={cognitoEnabled ? handleOpenDialog : handleEnableUser}>
        {cognitoEnabled ? 'Suspend user' : 'Unsuspend user'}
      </MenuItem>
      <Form
        onSubmit={handleDisableUser}
        initialValues={{ note: '' }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Dialog
                open={openDialog}
                onCancel={handleCloseDialog}
                title="Suspension reason"
                onConfirm={handleSubmit}
                confirmText="Suspend"
                submitting={submitting}>
                <Input name="note" label="Reason" validate={required} />
              </Dialog>
            </form>
          );
        }}
      />
    </>
  );
});

export default EnableUserAction;
