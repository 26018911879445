import React from 'react';
import { DateField as RADateField } from 'react-admin';

const DateField = (props) => (
  <RADateField
    variant="body1"
    options={{ year: 'numeric', month: 'short', day: 'numeric' }}
    {...props}
  />
);

export default DateField;
