import React, { useEffect, useState, useMemo } from 'react';
import {
  TextField,
  useRefresh,
  useDataProvider,
  useVersion,
  useRedirect,
  Filter,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core';
import { Datagrid, List } from 'components/list';
import { DateField, LabeledUrlField } from 'components/fields';
import AmountsField from './components/AmountsField';
import CurrencyCard from './components/CurrencyCard';
import ChangeBalanceDialog from './components/ChangeBalanceDialog';
import DetailsField from './components/DetailsField';
import ManagementTab from './components/ManagementTab';
import RefundField from './components/RefundField';
import RefundDialog from './components/RefundDialog';
import InitialBalanceField from './components/InitialBalanceField';
import SearchInput from '../../components/list/SearchInput';
import UserDetailsHeader from './components/UserDetailsHeader';
import ActivityTab from './components/ActivityTab';
import StripeField from './components/StripeField';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '50%',
    '& .filter-field': {
      minWidth: 320,
      width: '100%',
    },
  },
  header: {
    paddingBottom: theme.spacing(1),
  },

  tabs: {
    padding: theme.spacing(0, 2),
  },

  managementCard: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
  },

  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },

  backButton: {
    paddingRight: '8px',
  },
}));

const PURCHASE_PAGE = 'PURCHASE,ADMIN_PURCHASE,PURCHASE_ADJUSTMENT';
const DEPOSIT_PAGE = 'DEPOSIT,ADMIN_DEPOSIT,DEPOSIT_ADJUSTMENT,REFUND';
const ALL_PAGE = 'ALL';
const DEVICES_PAGE = 'DEVICES';
const MANAGEMENT_PAGE = 'MANAGEMENT';
const ACTIVITY_PAGE = 'ACTIVITY';

const UserFilters = (props) => {
  const { isDepositPage } = props;

  const classes = useStyles();

  return (
    <Filter {...props} classes={classes}>
      {!isDepositPage && (
        <SearchInput
          label="Tracking number"
          source="track.number"
          alwaysOn
          onlySearch
          loading={props.loading}
          fullWidth
        />
      )}

      {isDepositPage && (
        <SearchInput
          label="Deposit amount"
          source="total.amount"
          alwaysOn
          onlySearch
          loading={props.loading}
          fullWidth
        />
      )}
    </Filter>
  );
};

const UserShow = (props) => {
  const { id } = props;
  const [type, setType] = useState(ALL_PAGE);
  const classes = useStyles();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const version = useVersion();

  const [user, setUser] = useState<any>({});

  useEffect(() => {
    dataProvider.getOne('users', { id }).then(({ data }) => setUser(data));
  }, [id, dataProvider, version]);

  const [balance, setBalance] = useState<any>(null);
  const [balanceAction, setBalanceAction] = useState<any>(null);
  const [currentTransacion, setCurrentTransaction] = useState<any>(null);

  const loadData = () => {
    setBalance(null);

    dataProvider.get('userBalance', { id }).then(({ data }) => setBalance(data));
  };

  useEffect(() => {
    loadData();
  }, []); //eslint-disable-line

  const isDeviceManager = useMemo(() => {
    return type === DEVICES_PAGE;
  }, [type]);

  const isUserManagement = useMemo(() => type === MANAGEMENT_PAGE, [type]);
  const isActivityTab = useMemo(() => type === ACTIVITY_PAGE, [type]);

  const handleChangeBalanceRequested = (action) => {
    setBalanceAction(action);
  };

  const handleChangeTab = (event, value) => {
    setType(value);
  };

  const handleCloseBalanceDialog = () => {
    setBalanceAction(null);
  };

  const handleChangeCompleted = () => {
    setBalanceAction(null);
    refresh();
    loadData();
  };

  const handleRefund = (record) => {
    setCurrentTransaction(record);
  };

  return (
    <Box>
      <UserDetailsHeader user={user} />

      <Grid container spacing={4} className={classes.header}>
        <Grid item lg={6} xs={12}>
          <CurrencyCard
            currencyCode="USD"
            balance={balance}
            onChangeBalanceRequested={handleChangeBalanceRequested}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <CurrencyCard
            currencyCode="LABEL"
            balance={balance}
            onChangeBalanceRequested={handleChangeBalanceRequested}
          />
        </Grid>
      </Grid>

      <Tabs
        className={classes.tabs}
        value={type}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeTab}>
        <Tab value={PURCHASE_PAGE} label="Purchase" />
        <Tab value={DEPOSIT_PAGE} label="Deposit" />
        <Tab value={ALL_PAGE} label="All Transactions" />
        <Tab value={DEVICES_PAGE} label="Devices" />
        {!user?.deletedAt && <Tab value={MANAGEMENT_PAGE} label="Management" />}
        <Tab value={ACTIVITY_PAGE} label="Activity" />
      </Tabs>

      {!isDeviceManager && !isUserManagement && !isActivityTab && (
        <List
          {...props}
          resource="userHistory"
          filter={{
            _id: id,
            type: type !== 'ALL' ? type : undefined,
          }}
          filters={<UserFilters isDepositPage={type === DEPOSIT_PAGE} />}
          sort={{ field: 'createdAt', order: 'DESC' }}
          actions={null}
          bulkActionButtons={false}>
          <Datagrid
            rowClick={(_id, _basePath, record) => {
              if (
                record.type === 'PURCHASE' &&
                !record?.details?.description.includes('Pickup') &&
                !record?.details?.fraudCapture
              ) {
                redirect('show', '/shipments', record?.shipmentInfo?.id);
              }
            }}>
            <DateField label="Date" source="createdAt" variant="body1" />

            <DetailsField label="Details" source="details.description" />

            <LabeledUrlField
              label="Tracking number"
              source="shipmentInfo.trackLink"
              labelSource="shipmentInfo.trackNumber"
              enableCopy
            />

            <AmountsField label="Amount" source="amounts" />

            {type === ALL_PAGE ? <TextField label="Type" source="type" variant="body1" /> : <></>}

            <TextField label="Status" source="status" variant="body1" />

            <InitialBalanceField label="Updated RSM balance" />

            <StripeField label="" />

            <RefundField onRefund={handleRefund} />
          </Datagrid>
        </List>
      )}
      {isDeviceManager && (
        <List
          {...props}
          resource="userDevices"
          filter={{
            userId: user?.cognitoUserId /*profile.sub*/,
          }}
          actions={null}
          bulkActionButtons={false}
          pagination={false}>
          <Datagrid>
            <TextField label="Device Id" source="deviceId" variant="body1" sortable={false} />
            <TextField
              label="Firmware Version"
              source="firmwareVersion"
              variant="body1"
              sortable={false}
            />
            <TextField label="Mac Address" source="macAddress" variant="body1" sortable={false} />
          </Datagrid>
        </List>
      )}

      {isUserManagement && !user?.deletedAt && <ManagementTab user={user} />}

      {isActivityTab && <ActivityTab {...props} />}

      <ChangeBalanceDialog
        user={user}
        balance={balance}
        action={balanceAction}
        onComplete={handleChangeCompleted}
        onCancel={handleCloseBalanceDialog}
      />
      {!!currentTransacion && (
        <RefundDialog
          onCancel={() => setCurrentTransaction(null)}
          currentTransaction={currentTransacion}
          user={user}
          balance={balance}
          loadData={loadData}
        />
      )}
    </Box>
  );
};

export default UserShow;
